import React from 'react';
import { Link } from 'react-router-dom';

class About extends React.Component {
  render() {
    return (
      <div className="About">
        <nav><Link to="/">top</Link> &gt; About</nav>

        <h1>About</h1>

        <p>
          1. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          2. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          3. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          4. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          5. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          6. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          7. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          8. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          9. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          10. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          11. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          12. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          13. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          14. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          15. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          16. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          17. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          18. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          19. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>

        <p>
          20. This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.<br/>
          This is Sample Page.This is Sample Page.This is Sample Page.This is Sample Page.
        </p>
      </div>
    );
  }
}

export default About;
